import {userTypesList} from './constants';
import {filterSlots} from './filterSlots';
import pubtechSlots from './data/adv_slots_pubtech.json';

const DIV_ID_PREFIX = 'div-gpt-ad-';
const slotsAds = [];
let isPubtechEnabled = false;

window.pcp = window.pcp || [];
const pcp = window.pcp;
const pending = window.pcp;
window.requireConsent = window.requireConsent || [];

function executeTask(cmd) {
    if (typeof cmd === 'function') cmd();
}

function defineSlot(slot, sizes, divId, outOfPage) {
    const googletag = window.googletag;
    const slotDefine = outOfPage
        ? googletag.defineOutOfPageSlot(slot, divId).addService(googletag.pubads())
        : googletag.defineSlot(slot, sizes, divId).addService(googletag.pubads());

    googletag.cmd.push(() => {
        slotsAds[divId] = slotDefine
    });
}

function enableServices() {
    const googletag = window.googletag;
    googletag.cmd.push(function () {
        
        if(typeof googletag.pubadsReady === "undefined") {
            console.log('------------ pubadsReady is undefined')
            if (!isPubtechEnabled) googletag.pubads().enableLazyLoad();
            googletag.pubads().enableSingleRequest();
            googletag.pubads().collapseEmptyDivs(); // collapsing empty divs raises CLS
            if (isPubtechEnabled) googletag.pubads().disableInitialLoad();
            // googletag.pubads().disableInitialLoad();
            googletag.pubads().setCentering(true);
            googletag.enableServices();
        }

    });
}

function setPageTargeting() {
    const googletag = window.googletag;
    const pcp = window.pcp;
    const targetingDict = pcp.MonrifPolopolyUtility.TagManager || {};

    targetingDict.device = pcp.device;
    if (pcp.thirdLevelDomain) targetingDict.site = pcp.thirdLevelDomain;

    googletag.cmd.push(function () {
        for (const key in targetingDict) googletag.pubads().setTargeting(key, targetingDict[key]);
    });
}

function onlyDefined(slots, definePosition, isPubtechEnabled, pubtechSlots) {

    const unit = (isPubtechEnabled)
        ? definePosition.filter(x => !pubtechSlots.includes(x))
        : definePosition;

    const slotsFiltered = unit.map((ud) => slots.find((s) => s.unit === ud));
    return {unit: unit, extend: slotsFiltered };
}

function speedPathBuild(custom_adunit_dir, unit) {
    const speedConf = (pcp.MonrifPolopolyUtility.aggregatorValues || {}).speed || {};
    let adUnitPath = speedConf.code || '';
    adUnitPath += custom_adunit_dir.replace(/\{[^}]+\}/gi, function (m) {
        const key = m.substring(1, m.length - 1).toLowerCase();
        return (key in speedConf) ? speedConf[key] : '';
    });
    if (!adUnitPath.match(/\/$/)) adUnitPath += '/';
    adUnitPath += unit;
    return adUnitPath
}

function unitPath(path, custom_adunit_dir, unit) {
    return (!typeof custom_adunit_dir === 'undefined' && !custom_adunit_dir)
        ? speedPathBuild(custom_adunit_dir, unit)
        : `${path}${unit}`
}

function init(slots) {
    window.pcp = {
        define: function (path, positions, device) {
            const pcp = window.pcp;
            pcp.device = device;
            if ('globals' in window) {
                pcp.thirdLevelDomain = window.globals.customDomain;
            }
            pcp.MonrifPolopolyUtility = window.MonrifPolopolyUtility || {};
            pcp.__defined = [];

            const filteredSlotAdv = onlyDefined(slots, positions, isPubtechEnabled, pubtechSlots)
            filteredSlotAdv.extend.forEach(slotOptions => {

                if (slotOptions && (slotOptions.device === pcp.device || slotOptions.device.includes(pcp.device))) {

                    const adUnitPath = unitPath(path, slotOptions.custom_adunit_dir, slotOptions.unit);

                        defineSlot(
                            adUnitPath,
                            slotOptions.sizes,
                            DIV_ID_PREFIX + slotOptions.unit,
                            slotOptions.outOfPage
                        );
                        pcp.__defined.push(DIV_ID_PREFIX + slotOptions.unit);

                } else {
                    if(slotOptions.unit) {
                        console.warn(slotOptions.unit, 'not defined');
                    } else {
                        console.warn('slotOptions.unit undefined and not defined');
                    }
                    
                }
            })
            setPageTargeting();
            enableServices();
        },
        push: function (cmd) {
            executeTask(cmd);
        },
        display: function (divId) {
            const pcp = window.pcp;          
            if(!pubtechSlots.includes(divId.replace(DIV_ID_PREFIX, '')) && (pcp.__defined.includes(divId))) {
                window.googletag.cmd.push(() => {
                    console.log(`------------ Monrif display ${divId}`);
                    console.log(`------------ Monrif refresh ${slotsAds[divId]}`);
                    window.googletag.display(divId);
                    window.googletag.pubads().refresh([slotsAds[divId]]);
                });
            }
        },
    };
    for (let i = 0; i < pending.length; i++) {
        executeTask(pending[i]);
    }
}

export const pcp2Init = (userType) => {
    const {globals = {}} = window;

    isPubtechEnabled = globals.isPubtechEnabled;

    if(!globals.userAbbonato) {
        const slots = filterSlots(userTypesList, userType, globals.isArticle, globals.isMobile);
        init(slots);
    }
};
