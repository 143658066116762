import qnAdvSlotList from './data/adv_slots.json';
import qnAdvSlotListRegisteredMobile from './data/adv_slots_registered_mobile.json';
import qnAdvSlotListSubscriberDesktop from './data/adv_slots_subscriber_desktop.json';
import qnAdvSlotListSubscriberMobile from './data/adv_slots_subscriber_mobile.json';
import { log } from '../utility';

export function filterSlots(userTypesList, userType, isArticle, isMobile) {
  // solo su articolo
  if (isArticle) {
    // subscriber o sfogliatore
    if ([userTypesList.SUBSCRIBER, userTypesList.SFOGLIATORE].includes(userType)) {
      if (isMobile) {
        // mobile
        log('ads json per i subscriber e sfogliatore, mobile');
        return qnAdvSlotListSubscriberMobile;
      } else {
        // desktop
        log('ads json per i subscriber e sfogliatore, desktop');
        return qnAdvSlotListSubscriberDesktop;
      }
    }
    // registered
    if ([userTypesList.REGISTERED].includes(userType)) {
      // mobile
      if (isMobile) {
        log('ads json per i registered, mobile');
        return qnAdvSlotListRegisteredMobile;
      }
    }

    // se pagina evento, nascondi ads ---- <<<
  }
  return qnAdvSlotList;
}
