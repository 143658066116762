import { userTypesList } from './constants';
import { log } from '../utility';

const ampAds = (userType) => {
  log('ampAds');

  const { isArticle = false } = window.globals;

  if (isArticle && [userTypesList.ANONYMOUS, userTypesList.REGISTERED].includes(userType)) {
    log('Attivo gli ampAds js per', userType);

    const tag = document.createElement('script');
    tag.type = 'text/javascript';
    tag.src = 'https://cdn.ampproject.org/v0.js';
    tag.async = 'async';
    const node = document.getElementsByTagName('script')[0];
    node.parentNode.insertBefore(tag, node);
  }
};

export default ampAds;
