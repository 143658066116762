import { log } from '../utility';
import { pcp2Init } from './pcprincipal-3';
// import outbrain from './outbrain';
import viralize from './viralize';
import ampAds from './amp-ads';

// wait for auuth session, push in requireConsent pcp2Init with userType
window.authSession.then((userInfo) => {
  const userType = userInfo.paymentStatus || userInfo.loginStatus;
  log(userType);
  window.requireConsent.push(function () {
    pcp2Init(userType);
    // outbrain(userType);
    viralize(userType);
    ampAds(userType);
  });
});
