export function isDesktop() {
  if (window.innerWidth > 768) {
    return true;
  } else {
    return false;
  }
}

export function isMobile() {
  if (window.innerWidth <= 768) {
    return true;
  } else {
    return false;
  }
}

export function isHome() {
  return (
    'MonrifPolopolyUtility' in window &&
    'page_type' in window.MonrifPolopolyUtility &&
    window.MonrifPolopolyUtility.page_type === 'home'
  );
}

export function isiPadOriPod() {
  const ua = navigator.userAgent;
  const isiPad = /iPad/i.test(ua) || /iPhone OS 3_1_2/i.test(ua) || /iPhone OS 3_2_2/i.test(ua);
  return isiPad;
}

export function getCookie(cname) {
  /*
   * See:
   * https://www.w3schools.com/js/js_cookies.asp
   * https://stackoverflow.com/questions/4003823/javascript-getcookie-functions/4004010
   */
  const name = cname + '=';
  const ca = document.cookie.split(';');
  let value = '';
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      value = c.substring(name.length, c.length);
    }
  }
  try {
    // there can be quotes around cookie
    value = value.match('^"?([^"]*)"?$')[1];
  } catch (error) {}
  return value;
}

export function pipe(...fns) {
  return (x) => fns.reduce((v, f) => f(v), x);
}

export const isDebug = new URLSearchParams(window.location.search).get('debug') !== null;

export const log = (msg1, msg2 = '') =>
  isDebug && console.debug(`${JSON.stringify(msg1)} ${JSON.stringify(msg2).length > 2 ? JSON.stringify(msg2) : ''}`);

export const getThemeColor = () => {
  const metaThemeColor = document.getElementsByName("theme-color");
  if (metaThemeColor.length > 0) {
    return metaThemeColor[0].getAttribute('content');
  } else {
    return '#dddddd';  // a dull gray
  }
}