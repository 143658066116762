import { userTypesList } from './constants';
import { log } from '../utility';

const viralize = (userType) => {
  log('viralize js');
  document.querySelectorAll('.viralize-video-placeholder').forEach((el) => {
    const paramsBase = el.dataset?.params;
    const hasClickActivation = [userTypesList.SUBSCRIBER, userTypesList.SFOGLIATORE].includes(
      userType,
    );
    const params = hasClickActivation
      ? paramsBase.replace(/activation=adfill|activation=onload/, 'activation=click')
      : paramsBase;

    log('injecto viralize per ', el.id);
    log(paramsBase);
    log(userType, params);
    const tag = document.createElement('script');
    tag.type = 'text/javascript';
    tag.src = `//content.viralize.tv/display/?${params}`;
    tag.async = 'async';
    tag['data-wid'] = 'auto';
    const node = document.getElementsByTagName('script')[0];
    node.parentNode.insertBefore(tag, node);
  });
};

export default viralize;
